import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "out" }
const _hoisted_2 = { class: "bg" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "entry_list" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "desc" }
const _hoisted_7 = { class: "card_list" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "desc" }
const _hoisted_10 = ["src"]

import { lang } from '@/lang';
import { computed } from 'vue';

//词条

export default /*@__PURE__*/_defineComponent({
  __name: 'CompetitiveAdvantages',
  setup(__props) {

const EntryList = computed(() => [
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg1.png'),
    title: computed(() => lang.v.CompetitiveAdvantages1),
    content: computed(() => lang.v.CompetitiveAdvantages1_1),
    index: 1,
  },
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg2.png'),
    title: computed(() => lang.v.CompetitiveAdvantages2),
    content: computed(() => lang.v.CompetitiveAdvantages2_1),
    index: 2,
  },
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg3.png'),
    title: computed(() => lang.v.CompetitiveAdvantages3),
    content: computed(() => lang.v.CompetitiveAdvantages3_1),
    index: 3,
  },
]);
const CardList = computed(() => [
  {
    imgUrl: require('@/assets/img/newHome/CardImg1.png'),
    title: computed(() => lang.v.CardTitle1),
    content: computed(() => lang.v.CardDesc1),
    index: 1,
  },
  {
    imgUrl: require('@/assets/img/newHome/CardImg2.png'),
    title: computed(() => lang.v.CardTitle2),
    content: computed(() => lang.v.CardDesc2),
    index: 2,
  },
  {
    imgUrl: require('@/assets/img/newHome/CardImg3.png'),
    title: computed(() => lang.v.CardTitle3),
    content: computed(() => lang.v.CardDesc3),
    index: 3,
  },
  {
    imgUrl: require('@/assets/img/newHome/CardImg4.png'),
    title: computed(() => lang.v.CardTitle4),
    content: computed(() => lang.v.CardDesc4),
    index: 4,
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(lang).v.CompetitiveAdvantages) + " ", 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(lang).v.CompetitiveAdvantages), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(EntryList.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.index,
          class: "entry_item",
          style: _normalizeStyle({ 'background-image': `url(${item.imgUrl})` })
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(item.content), 1)
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(CardList.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "card_item",
          key: item.index
        }, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(item.content), 1),
          _createElementVNode("img", {
            src: item.imgUrl,
            alt: "",
            style: {"height":"3.2rem","width":"3.2rem"}
          }, null, 8, _hoisted_10)
        ]))
      }), 128))
    ])
  ]))
}
}

})