import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "down_load" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "desc" }
const _hoisted_4 = { class: "introduce" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

import { lang } from '@/lang';
import ArrowRightUrl from '@/assets/img/newHome/ArrowRight.png';
import LoginUrl from '@/assets/img/newHome/login.png';
import IconUrl from '@/assets/img/newHome/icon.png';
import UpDownUrl from '@/assets/img/newHome/updown.png';
import router from '@/router';
import { onDownload } from '@/utils/download';

// 登录

export default /*@__PURE__*/_defineComponent({
  __name: 'DownLoad',
  setup(__props) {

const onLogin = () => {
  router.push('/register');
};

const onWeb = () => {
  window.open(window.webVertion, '_blank');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(IconUrl),
      alt: "no data",
      class: "icon_img"
    }, null, 8, _hoisted_2),
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "title" }, "MovShot", -1)),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(lang).v.AppDesc), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(lang).v.AppIntroduce), 1),
    _createElementVNode("button", {
      class: "btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onDownload) && _unref(onDownload)(...args)))
    }, [
      _createElementVNode("img", {
        src: _unref(UpDownUrl),
        alt: "",
        style: {"position":"relative","top":"-0.01rem"}
      }, null, 8, _hoisted_5),
      _createElementVNode("span", null, _toDisplayString(_unref(lang).v.DownloadNow), 1)
    ]),
    _createElementVNode("button", {
      class: "btn",
      onClick: onLogin
    }, [
      _createElementVNode("img", {
        src: _unref(LoginUrl),
        alt: "",
        style: {"position":"relative","top":"-0.01rem"}
      }, null, 8, _hoisted_6),
      _createElementVNode("span", null, _toDisplayString(_unref(lang).v.SignIn), 1)
    ]),
    _createElementVNode("p", {
      class: "movShot",
      onClick: onWeb
    }, [
      _createTextVNode(_toDisplayString(_unref(lang).v.WebVersion), 1),
      _createElementVNode("img", {
        src: _unref(ArrowRightUrl),
        alt: "right",
        style: {"width":"0.16rem","margin-left":"0.05rem","position":"relative","top":"-0.02rem"}
      }, null, 8, _hoisted_7)
    ])
  ]))
}
}

})